<template>
  <div>
    <v-container fluid class="my-16 py-16">

      <v-row class="d-flex justify-center my-9">
        <v-col cols="12" sm="11" md="9" lg="9">
          <h1 class="text-center font-weight-black" style="font-size: 60px; color: #000000;">{{$t('plans.titles.planLizto')}}</h1>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="11" md="9" lg="6">
          <v-select
            v-model="nationalType"
            item-text="text"
            item-value="value"
            :items="plansCountries"
            return-object
            outlined
            item-color="#000000"
            color="#000000"
            height="30"
            class="mx-5"
            @change="onChangeNationalType"
          >
            <template v-slot:item="{ item }">
              <div class="d-flex align-center">
                <v-img v-if="item.flag" :src="item.flag" max-height="20" max-width="30" class="mr-2"></v-img>
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <span>{{ $t(item.text.langKey) }}</span>
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center">
                <v-img v-if="item.flag" :src="item.flag" max-height="20" max-width="30" class="mr-2"></v-img>
                <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                <span>{{ $t(item.text.langKey) }}</span>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row class="justify-center">
        <v-col cols="12" sm="11" md="9" lg="9">
          <v-btn-toggle v-model="frequency" mandatory class="d-flex stretch mx-5">
            <v-btn v-for="option in getFrequencies"
              :key="option.value"
              :value="option"
              class="plans-button flex-grow-1 font-weight-bold text-black"
              :class="{ 'selected': frequency.value === option.value }"
            >
              {{ $t(option.langKey) }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="12" md="10" lg="11">
          <div class="shadows">
            <table class="sticky-table">
              <thead>
                <tr>
                  <th class="px-8 pt-2" style="width: 245px !important; height: auto;"></th>
                  <th v-for="plan in getPlansByCountries()" :key="plan.id" class="px-0 pt-2  text-center" style="width: 210px !important; height: auto; border-bottom: 0px;" >
                    <span v-if="plan.mostPopular" class="d-none d-md-block text-body-2 px-2 py-1 text-white" style="background-color:#000000;">{{ $t('plans.mostSeller') }}</span>
                    <h5 class="text-md-h6 text-lg-h5 hyphen-text">
                      {{ $t(plan.name.langKey) }}
                    </h5>
                    <div v-if="plan.showPrices">
                      <div v-if="nationalType.value == 'co'" class="text-subtitle-1 text-md-h5 text-lg-h4 font-weight-bold">{{ plan.prices[frequency.value] }}</div>
                      <div v-else-if="nationalType.value == 'international'" class="text-subtitle-1 text-md-h5 text-lg-h4 font-weight-bold">{{ plan.pricesInternational[frequency.value] }}</div>
                      <div v-else class="text-subtitle-1 text-md-h5 text-lg-h4 font-weight-bold">{{ $t('plans.notAvailable') }}</div>
                    </div>

                    <h5 class="text-md-h5 font-weight-bold text-black">/{{ plan.typeMoney[nationalType.value] }}</h5>

                    <v-btn
                      class="mx-4 mx-md-12 text-white font-weight-bold"
                      color="#000000"
                      elevation="2"
                      @click="openPurchaseLink"
                    >
                      <span>{{ $t('plans.max.buttonPurchase') }}</span>
                    </v-btn>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td colspan="6" >
                    <v-list>
                      <v-list-group
                        v-for="(section, idx ) in sections"
                        :key="`${idx}-${section.name.langKey}`"
                        v-model="section.showActive"
                        :prepend-icon="section.showActive ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'"
                        append-icon=""
                        no-action
                        color="#000000"
                        class="ma-1 rounded-lg"
                        style="background-color: #f3f3f4;"
                        :id="`${idx}-${section.name.langKey}`"
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">{{ $t(section.name.langKey) }}</v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-simple-table class="aligned-table">
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="feature in getFeaturesByCountries(section)"
                                :key="feature.name.langKey"
                              >
                                <td style="width: 245px !important;">
                                  <span class="hyphen-text font-weight-bold">
                                    {{ $t(feature.name.langKey) }}
                                  </span>
                                </td>
                                <td v-for="plan in plans" :key="plan.id" class="text-center " style=" width: 210px !important;">
                                  <div v-if="typeof feature.plans[plan.planName] === 'string'">
                                    {{ feature.plans[plan.planName]}}
                                  </div>
                                  <template v-else>
                                    <div  v-if="feature.plans[plan.planName] === true">
                                      <span class="material-icons-outlined">check_box</span>
                                    </div>
                                    <div v-else>
                                      <v-icon >mdi-minus</v-icon>
                                    </div>
                                  </template>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-list-group>
                    </v-list>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>

      <hr class="my-16">

      <v-row class="justify-center">
        <v-col sm="12" md="5" lg="4">
          <div class="shadows pa-5 rounded-xl" style="padding-top: 4rem !important; padding-bottom: 4rem !important; border: 1px solid #dfdfdf;">
            <h4 class="d-flex align-center justify-start text-h4 text-lg-h3 font-weight-black">
              <svg xmlns="http://www.w3.org/2000/svg" height="45px" viewBox="0 0 24 24" width="45px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM17.99 9l-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99z"/>
              </svg>
              <span class="ml-2">{{ $t('plans.max.liztoMaxCompanies') }}</span>
            </h4>

            <div class="mx-4 mx-md-3 mt-8">
              <ul class="pl-0">
                <li class="no-bullets d-flex">
                  <span class="text-h5">
                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.602273 4.99432L1.95455 3.625L4.71023 6.33523L10.6705 0.397727L12.0341 1.76705L4.71023 9.0625L0.602273 4.99432Z" fill="#0F172A"></path>
                    </svg>
                  </span>
                  <div class="ml-3">
                    <span class="text-h6">{{ $t('plans.max.designedSolution') }}</span>
                  </div>
                </li>
                <li class="no-bullets d-flex">
                  <span class="text-h5">
                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.602273 4.99432L1.95455 3.625L4.71023 6.33523L10.6705 0.397727L12.0341 1.76705L4.71023 9.0625L0.602273 4.99432Z" fill="#0F172A"></path>
                    </svg>
                  </span>
                  <div class="ml-3">
                    <span class="text-h6">{{ $t('plans.max.infoMassive') }}</span>
                  </div>
                </li>
              </ul>
            </div>

            <h6 style="color:#1c244b" class="font-weight-bold mx-4 mx-md-3 my-7 text-h6">{{ $t('plans.max.liztoCompaines') }}</h6>

            <div class="d-flex justify-center">
              <v-btn
                class="mx-4 mx-md-12 text-white font-weight-bold"
                color="#000000"
                elevation="2"
                @click="openWhatsapp"
              >
                <span>{{ $t('plans.max.buttonAdvice') }}</span>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <hr class="my-16">

      <div v-if="nationalType.value == 'co'">
        <v-row class=" justify-center">
          <v-col cols="12" sm="11" md="9" lg="9">
            <h1 class="text-center my-5 complement-main-title text-h3 font-weight-black"> {{ $t('plans.titles.complementsTitle') }}</h1>
            <p class="text-center my-7 text-h5">{{ $t('plans.titles.complementsSubTitle') }}</p>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="12" md="10" lg="11">
            <div class="shadows pa-4">
              <table class="sticky-table">
                  <thead>
                    <tr class="border-bottom">
                      <th scope="row" style="word-break: break-all; width: 245px !important; height: auto;"></th>
                      <th v-for="complement in getComplementsNotEmail" :key="complement.id" class="text-center" style="width: 210px !important; height: auto; border-bottom: 0px;" >
                        <h5 class="text-md-h6 text-lg-h5 font-weight-bold">
                          <v-icon class="text-h6 text-lg-h5">{{complement.textIcon}}</v-icon>{{ $t(complement.name.langKey) }}
                        </h5>
                        <h5 class="text-md-h5 font-weight-bold text-black">/{{ complement.typeMoney[nationalType.value] }}</h5>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="complementSection in complementSections"
                      :key="complementSection.name.langKey"
                      class="border-bottom"
                    >
                      <td style="width: 245px !important;">
                        <span class="hyphen-text font-weight-bold">
                          {{$t(complementSection.name.langKey)}}
                        </span>
                      </td>
                      <td v-for="complement in getComplementsNotEmail" :key="complement.id" class="text-center " style=" width: 210px !important;">
                        <div v-if="typeof complementSection.complements[complement.complementName] === 'string'" class="text-h5 font-weight-bold">
                          {{ complementSection.complements[complement.complementName]}}
                        </div>
                        <template v-else>
                          <div  v-if="complementSection.complements[complement.complementName] === true">
                            <span class="material-icons-outlined">check_box</span>
                          </div>
                          <div v-else>
                            <v-icon >mdi-minus</v-icon>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </v-col>
        </v-row>

        <hr class="my-16">

        <v-row class="justify-center">
          <v-col v-for="(electronic, idxElectronic) in complementsElectronic" :key="`${idxElectronic}-${electronic.id}`" sm="12" md="5" lg="4" xl="3">
            <div class="shadows pa-5 rounded-xl d-flex flex-column justify-space-between" style="height: 100%; padding-top: 4rem !important; padding-bottom: 4rem !important; border: 1px solid #dfdfdf;">
              <div>
                <h4 class=" text-h5 text-lg-h4 font-weight-black text-break">
                  {{ $t(electronic.name.langKey) }}
                </h4>

                <h4 class=" text-h6 text-lg-h5 font-weight-black">
                  {{ electronic.price }} <span>{{  }}</span>
                </h4>
              </div>
              <div>

                <div class="my-8">
                  <ul class="pl-0">
                    <li v-for="(feature, idxFeature) in electronic.features" :key="idxFeature" class="no-bullets d-flex">
                      <span class="text-h5">
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.602273 4.99432L1.95455 3.625L4.71023 6.33523L10.6705 0.397727L12.0341 1.76705L4.71023 9.0625L0.602273 4.99432Z" fill="#0F172A"></path>
                        </svg>
                      </span>
                      <div class="ml-3">
                        <span class="text-h6">{{ $t(feature.name.langKey) }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="d-flex justify-center">
                <v-btn
                  class="mx-4 mx-md-12 text-white font-weight-bold"
                  color="#000000"
                  elevation="2"
                  @click="openWhatsapp"
                >
                  <span>{{ $t('plans.max.buttonAdvice') }}</span>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>

        <hr class="my-16">

        <v-row class=" justify-center">
          <v-col cols="12" sm="11" md="9" lg="9">
            <h1 class="text-center my-5 complement-main-title text-h3 font-weight-black">Paquetes de documentos electrónicos</h1>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="12" md="9" lg="7">
            <div class="shadows pa-4">
              <table class="sticky-table">
                  <tbody>
                    <tr
                      v-for="electronicDocsPackage in electronicDocsPackages"
                      :key="electronicDocsPackage.name"
                      class="border-bottom"
                    >
                      <td class="text-h6 hyphen-text font-weight-bold">
                        {{electronicDocsPackage.name}}
                      </td>
                      <td class="text-h6">
                        {{electronicDocsPackage.description}}
                      </td>
                      <td class="text-h5 font-weight-bold text-right">
                        {{electronicDocsPackage.price}}
                      </td>
                    </tr>
                  </tbody>
              </table>
            </div>

            <p class="text-h6 mt-4">Los paquetes de documentos electrónicos no vencen. Solo vencen hasta agotar la cantidad de documentos electrónicos que tienes en tu cuenta, al agotar la cantidad de documentos electrónicos debes volver a recargar tu cuenta adquiriendo un nuevo paquete de documentos electrónicos.</p>
          </v-col>
        </v-row>

      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  data () {
    return {
      frequency: { value: 'monthly' },
      nationalType: { value: 'co' },
      plansCountries: [
        {
          value: 'co',
          text: { langKey: 'plansCountries.colombia.label' },
          flag: require('@/assets/sass/co.png')
        },
        {
          value: 'international',
          text: { langKey: 'plansCountries.international.label' },
          icon: 'mdi-earth'
        }
      ],
      frequencies: [
        { value: 'monthly', langKey: 'frequencies.monthly', countries: ['co', 'international'] },
        { value: 'trimester', langKey: 'frequencies.trimester', countries: ['co'] }, // 'international'
        { value: 'semiannual', langKey: 'frequencies.semiannual', countries: ['co'] } // 'international'
      ],
      plans: [
        {
          name: { langKey: ('plans.titles.entrepreneur') },
          planName: 'entrepreneur',
          id: 'plan-entrepreneur',
          countries: ['co', 'international'],
          mostPopular: false,
          showPrices: true,
          prices: { monthly: '$80.900', trimester: '$205,900', semiannual: '$365,900' },
          pricesInternational: { monthly: '$20', trimester: '$FALTA', semiannual: '$FALTA' },
          typeMoney: { co: 'COP', international: 'USD' }

        },
        {
          name: { langKey: ('plans.titles.professional') },
          planName: 'professional',
          id: 'plan-professional',
          countries: ['co', 'international'],
          mostPopular: false,
          showPrices: true,
          prices: { monthly: '$170,900', trimester: '$490,900', semiannual: '$790,900' },
          pricesInternational: { monthly: '$40', trimester: '$FALTA', semiannual: '$FALTA' },
          typeMoney: { co: 'COP', international: 'USD' }
        },
        {
          name: { langKey: ('plans.titles.premium') },
          planName: 'premium',
          id: 'plan-premium',
          countries: ['co', 'international'],
          mostPopular: true,
          showPrices: true,
          prices: { monthly: '$250,900', trimester: '$690,900', semiannual: '$1,200,900' },
          pricesInternational: { monthly: '$60', trimester: '$FALTA', semiannual: '$FALTA' },
          typeMoney: { co: 'COP', international: 'USD' }
        }
        /*
        {
          name: { langKey: ('plans.titles.max') },
          planName: 'max',
          id: 'plan-max',
          countries: ['co'],
          mostPopular: false,
          showPrices: false,
          prices: { monthly: 'Negociación', trimester: 'Negociación', semiannual: 'Negociación' },
          pricesInternational: { monthly: 'Negociación', trimester: 'Negociación', semiannual: 'Negociación' },
          showButton: true,
          textButton: '',
          typeMoney: { co: 'COP', international: 'USD' }
        },
        {
          name: { langKey: ('plans.titles.retail') },
          planName: 'retail',
          id: 'plan-retail',
          countries: ['co', 'international'],
          mostPopular: false,
          prices: { monthly: '$120.900', trimester: 'No Disponible', semiannual: '$600.900' },
          pricesInternational: { monthly: '$27.36', trimester: 'No Disponible', semiannual: '$136' },
          typeMoney: { co: 'COP', international: 'USD' }
        }
        */
      ],
      sections: [
        {
          name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.title' },
          countries: ['co', 'international'],
          showActive: true,
          features: [
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_1' }, // 'Integración para emisión de facturación electrónica y pos electrónico',
              countries: ['co'],
              plans: { entrepreneur: false, professional: true, premium: true, max: true, retail: false }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_2' }, // 'Facturación de servicios',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_3' }, // 'Facturación de productos',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_4' }, // 'Facturación y redención de bonos',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_5' }, // 'Cobros en espera',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_6' }, // 'Sacar Dinero (Anticipos, gasto, retiro)',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_7' }, // 'Devoluciones',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_8' }, // 'Movimientos',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_9' }, // 'Caja express',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_10' }, // 'Planes y combos',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_11' }, // 'Cuenta empresa',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_12' }, // 'Apertura y cierre de caja',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_13' }, // 'Resumen de ventas',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_14' }, // 'Recibos de pago (Créditos)',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_15' }, // 'Resumen de ventas (Detallado y resumido)',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            /*
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_16' }, // 'Puntos de fidelización',
              countries: ['co', 'international'],
              plans: { premium: true, max: true }
            },
            */
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_17' }, // 'Órdenes de servicio y comandas digitales',
              countries: ['co', 'international'],
              plans: { premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameBox.details.feature_18' }, // 'Informes de cierre de caja diario y por usuario cajero',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true, retail: true }
            }
          ]
        },
        {
          name: { langKey: 'plans.plansFeatures.allFeatures.nameScheduling.title' },
          showActive: true,
          features: [
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameScheduling.details.feature_1' }, // 'Agendamiento y reservas en línea por redes sociales',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameScheduling.details.feature_2' }, // 'Recordatorios vía Correo electrónico',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameScheduling.details.feature_3' }, // 'Recordatorios WhatsApp, Sms (Paquetes) Api WhatsApp ',
              countries: ['co', 'international'],
              plans: { entrepreneur: false, professional: false, premium: this.$t('plans.plansFeatures.allFeatures.nameScheduling.details.feature_3_1'), max: this.$t('plans.plansFeatures.allFeatures.nameScheduling.details.feature_3_1') }
            }
          ]
        },
        {
          name: { langKey: 'plans.plansFeatures.allFeatures.nameServices.title' },
          showActive: true,
          features: [
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameServices.details.feature_1' }, // 'Gestión de servicios',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameServices.details.feature_2' }, // 'Recordatorios de servicios (Oportunidades)',
              countries: ['co', 'international'],
              plans: { premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameServices.details.feature_3' }, // 'Formulación de productos en servicios para descargar productos automáticamente al vender un servicio. Función para hacer amarres de productos en los servicios',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true }
            }
            /*
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameServices.details.feature_4' }, // 'Modo BAC - Beta ',
              countries: ['co', 'international'],
              plans: { max: true }
            }
            */
          ]
        },
        {
          name: { langKey: 'plans.plansFeatures.allFeatures.nameStaff.title' },
          showActive: true,
          features: [
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_5' }, // 'Staff de trabajo y usuarios por sede',
              countries: ['co', 'international'],
              plans: { entrepreneur: '3', professional: '10', premium: '30', max: 'Ilimitados' }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameStaff.details.feature_1' }, // 'Gestión de comisiones por venta de servicios',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameStaff.details.feature_2' }, // 'Gestión de comisiones por venta de productos',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameStaff.details.feature_3' }, // 'Novedades especialistas',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameStaff.details.feature_4' }, // 'Créditos especialistas',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameStaff.details.feature_5' }, // 'Descuentos',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true }
            }
          ]
        },
        {
          name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.title' },
          showActive: true,
          features: [
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_1' }, // 'Base de datos de clientes',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_2' }, // 'Historial de clientes',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_3' }, // 'Fichas clínicas y consentimientos informados digitales',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_4' }, // 'Programa fidelización con puntos',
              countries: ['co', 'international'],
              plans: { premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_6' }, // 'Encuestas de satisfacción',
              countries: ['co', 'international'],
              plans: { professional: false, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_7' }, // 'Informes detallados y generales',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_9' }, // 'Campañas',
              countries: ['co', 'international'],
              plans: { professional: false, premium: true, max: true, retail: false }
            }
          ]
        },
        {
          name: { langKey: 'plans.plansFeatures.allFeatures.nameInventories.title' },
          showActive: true,
          features: [
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameInventories.details.feature_1' }, // 'Compras a proveedores',
              countries: ['co', 'international'],
              plans: { entrepreneur: false, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameInventories.details.feature_2' }, // 'Informes y analitica de inventarios',
              countries: ['co', 'international'],
              plans: { entrepreneur: false, professional: true, premium: true, max: true, retail: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameInventories.details.feature_3' }, // 'Gestión de productos',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameInventories.details.feature_4' }, // 'Control de inventarios',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameInventories.details.feature_5' }, // 'Traslados de productos',
              countries: ['co', 'international'],
              plans: { entrepreneur: false, professional: true, premium: true, max: true }
            },
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameInventories.details.feature_6' }, // 'Movimientos de productos (Entradas y Salidas)',
              countries: ['co', 'international'],
              plans: { professional: true, premium: true, max: true }
            }
            /*
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameInventories.details.feature_7' }, // 'Formulación de productos en servicios para descargar productos automáticamente al vender un servicio. Función para hacer amarres de productos en los servicios.',
              countries: ['co', 'international'],
              plans: { entrepreneur: false, professional: true, premium: true, max: true, retail: true }
            }
            */
          ]
        },
        {
          name: { langKey: 'plans.plansFeatures.allFeatures.nameInterfaces.title' },
          showActive: true,
          features: [
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameCustomers.details.feature_8' }, // 'Interfaces (Siigo, WO)',
              countries: ['co', 'international'],
              plans: { professional: this.$t('plans.plansFeatures.allFeatures.nameCustomers.details.feature_8_1'), premium: true, max: true, retail: this.$t('plans.plansFeatures.allFeatures.nameCustomers.details.feature_8_1') }
            }
          ]
        },
        {
          name: { langKey: 'plans.plansFeatures.allFeatures.nameSupport.title' },
          showActive: true,
          features: [
            {
              name: { langKey: 'plans.plansFeatures.allFeatures.nameSupport.details.feature_1' }, // 'Capacitación y soporte técnico (lunes a sábado 8am a 6pm) ',
              countries: ['co', 'international'],
              plans: { entrepreneur: true, professional: true, premium: true, max: true, retail: true }
            }
          ]
        }
      ],

      complements: [
        {
          name: { langKey: ('complements.titles.sms') },
          textIcon: 'mdi-message-processing',
          complementName: 'sms',
          id: 'complement-sms',
          countries: ['co'],
          typeMoney: { co: 'COP' }
        },
        {
          name: { langKey: ('complements.titles.whatsapp') },
          textIcon: 'mdi-whatsapp',
          complementName: 'whatsapp',
          id: 'complement-whatsapp',
          countries: ['co'],
          typeMoney: { co: 'USD', international: 'USD' }
        },
        {
          name: { langKey: ('complements.titles.email') },
          complementName: 'email',
          id: 'complement-email',
          countries: ['co'],
          typeMoney: { co: 'COP' }
        }
      ],
      electronicDocsPackages: [
        {
          name: 'Plan 1',
          description: '100 Documentos electrónicos. Incluye certificado digital',
          price: '$ 13,0000'
        },
        {
          name: 'Plan 2',
          description: '200 Documentos electrónicos. Incluye certificado digital',
          price: '$ 26,000'
        },
        {
          name: 'Plan 3',
          description: '600 Documentos electrónicos. Incluye certificado digital',
          price: '$ 78,000'
        },
        {
          name: 'Plan 4',
          description: '1.200 Documentos electrónicos. Incluye certificado digital',
          price: '$ 156,000'
        },
        {
          name: 'Plan 5',
          description: '2.000 Documentos electrónicos. Incluye certificado digital',
          price: '$ 260,000'
        },
        {
          name: 'Plan 6',
          description: '5.000 Documentos electrónicos. Incluye certificado digital',
          price: '$ 650,000'
        },
        {
          name: 'Plan 7',
          description: '10.000 Documentos electrónicos. Incluye certificado digital',
          price: '$ 1,300,000'
        }
      ],
      complementSections: [
        {
          name: { langKey: ('complements.sms_wa_plans.plan_500') }, // 'Plan de 500 mensajes',
          countries: ['co'],
          complements: { sms: '$18,400 + IVA' }
        },
        {
          name: { langKey: ('complements.sms_wa_plans.plan_1000') }, // 'Plan de 1000 mensajes',
          countries: ['co'],
          complements: { sms: '$25,900 + IVA', whatsapp: '$40' }

        },
        {
          name: { langKey: ('complements.sms_wa_plans.plan_2000') }, // 'Plan de 2000 mensajes',
          countries: ['co'],
          complements: { whatsapp: '$80' }
        },
        {
          name: { langKey: ('complements.sms_wa_plans.plan_3000') }, // 'Plan de 3000 mensajes',
          countries: ['co'],
          complements: { sms: '$75,900 + IVA', whatsapp: '$120' }
        },
        {
          name: { langKey: ('complements.sms_wa_plans.plan_5000') }, // 'Plan de 5000 mensajes',
          countries: ['co'],
          complements: { sms: '$100,900 + IVA', whatsapp: '$200' }
        },
        {
          name: { langKey: ('complements.sms_wa_plans.plan_10000') }, // 'Plan de 10000 mensajes',
          countries: ['co'],
          complements: { sms: '$180,900 + IVA', whatsapp: '$400' }
        },
        {
          name: { langKey: ('complements.sms_wa_plans.plan_20000') }, // 'Plan de 20000 mensajes',
          countries: ['co'],
          complements: { sms: '$300,900 + IVA' }
        }
      ],

      complementsElectronic: [
        {
          name: { langKey: ('complements.titles.payroll') },
          id: 'complement-electronic-payroll',
          countries: ['co'],
          typeMoney: { co: 'CO' },
          price: '$30.900',
          features: [
            {
              name: { langKey: 'complements.featuresPayroll.feature_1' } // 'Integración de Módulo Emisión documento Nómina Electrónica.'
            },
            {
              name: { langKey: 'complements.featuresPayroll.feature_2' } // 'Acompañamiento, actualización y ajustes al modelo de emisión.'
            },
            {
              name: { langKey: 'complements.featuresPayroll.feature_3' } // 'Soporte.'
            },
            {
              name: { langKey: 'complements.featuresPayroll.feature_4' } // '1 Sede o sucursal.'
            },
            {
              name: { langKey: 'complements.featuresPayroll.feature_5' } // 'Servicio mensual o anual.'
            }
          ]
        },
        {
          name: { langKey: ('complements.titles.electronic_documents') },
          complementName: 'FND',
          id: 'complement-electronic-fnd',
          countries: ['co'],
          typeMoney: { co: 'COP' },
          price: '$50.900',
          features: [
            {
              name: { langKey: 'complements.featuresElectronicDocuments.feature_1' } // 'Integración de módulo para Emisión Factura Electrónica y Emisión de Nómina Electrónica.'
            },
            {
              name: { langKey: 'complements.featuresElectronicDocuments.feature_2' } // 'Integración de módulo para Emisión Documento soporte.'
            },
            {
              name: { langKey: 'complements.featuresElectronicDocuments.feature_3' } // 'Acompañamiento, actualización, ajustes al modelo de emisión y recepción.'
            },
            {
              name: { langKey: 'complements.featuresElectronicDocuments.feature_4' } // 'Soporte.'
            },
            {
              name: { langKey: 'complements.featuresElectronicDocuments.feature_5' } // '1 Sede o sucursal.'
            },
            {
              name: { langKey: 'complements.featuresElectronicDocuments.feature_6' } // 'Servicio mensual o anual.'
            }
          ]
        }
      ]
    }
  },
  methods: {
    onChangeNationalType () {
      this.frequency = { value: 'monthly' }
    },
    getCurrencyAndDurationType () {
      if (this.nationalType === 'international') {
        return this.$t('plans.currency.usd')
      } else {
        return this.selectedPlanDuration === 'monthly' ? this.$t('plans.currency.monthlyNational') : this.$t('plans.currency.anualNational')
      }
    },
    calculatePriceComplement (planComplement) {
      if (this.selectedPlanDuration === 'monthly') {
        if (this.nationalType === 'international') {
          return planComplement.price_monthly_international
        } else {
          return planComplement.price_monthly
        }
      } else if (this.selectedPlanDuration === 'yearly') {
        if (this.nationalType === 'international') {
          return ''
        } else {
          return planComplement.price_yearly
        }
      }
    },
    getFilteredComplementsWhatsapp () {
      return this.planComplements.filter(complement => {
        if (complement.is_whatsapp) {
          if (this.selectedPlanDuration === 'monthly') {
            return complement.can_monthly && (complement.countries === null || complement.countries.includes(this.nationalType))
          } else if (this.selectedPlanDuration === 'yearly') {
            return complement.can_yearly && (complement.countries === null || complement.countries.includes(this.nationalType))
          }
        }
        return false
      })
    },
    getFilteredComplementsSms () {
      return this.planComplements.filter(complement => {
        if (complement.is_sms) {
          if (this.selectedPlanDuration === 'monthly') {
            return complement.can_monthly && (complement.countries === null || complement.countries.includes(this.nationalType))
          } else if (this.selectedPlanDuration === 'yearly') {
            return complement.can_yearly && (complement.countries === null || complement.countries.includes(this.nationalType))
          }
        }
        return false
      })
    },
    openWhatsapp () {
      const cellphone = '573187601559'
      const url = `https://api.whatsapp.com/send?phone=${cellphone}&text=Hola+Lizto+Software`
      window.open(url)
    },
    openPurchaseLink () {
      const url = 'https://app.lizto.co/portal/customers/lizto/register'
      window.open(url)
    },

    getFeaturesByCountries (section) {
      return section.features.filter(feature => {
        if (feature.countries.includes(this.nationalType.value)) {
          return feature
        }
      })
    },
    getPlansByCountries () {
      return this.plans.filter(plan => {
        if (plan.countries.includes(this.nationalType.value)) {
          return plan
        }
      })
    },
    handleNavigationMainPage (section) {
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/', hash: section, query: { l: this.$route.query.l } }) // Redirige al inicio y luego a la sección.
      } else {
        this.$vuetify.goTo(section)
      }
    }
  },
  computed: {
    getFrequencies () {
      return this.frequencies.filter(frequency => {
        if (frequency.countries.includes(this.nationalType.value)) {
          return frequency
        }
      })
    },
    getComplementsNotEmail () {
      return this.complements.filter(complement => {
        if (complement.countries.includes(this.nationalType.value)) {
          if (complement.complementName !== 'email') {
            return complement
          }
        }
      })
    }
  }
}
</script>

<style scoped="scss">
.col-with-border {
  border: 1px solid #000000;
}
.plans-button:active {
  color: rgb(0, 0, 0);
}
.v-btn::before {
  background-color: transparent;
}
.selected{
  background-color: #000000 !important ;
  color: white !important;
}
.sticky-table {
  width: 100%;
  border-collapse: collapse;
}
.sticky-table th,
.sticky-table td {
  padding: 10px;
  text-align: left;
}
.sticky-table th {
  position: sticky;
  top: 100px;
  background-color: #ffffff;
  z-index: 1;
}
.hyphen-text {
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.text-white {
  color:#ffffff
}
.text-black {
  color:#000000
}
.text-white-gray {
  color: #c0c0c0
}
.border-bottom {
  border-bottom: 1px solid rgb(216, 216, 216);
}
.shadows {
  box-shadow: 0px 10px 15px 1px rgba(0,0,0,0.1);
}
</style>
